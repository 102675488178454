<template>
  <div class="grid has-12-columns gap-1 has-background-white rounded-sml custom-field-item pt-075 pb-075" style="margin-bottom: 2px">
    <div class="is-2-columns is-flex align-items-start">
      <div class="is-flex align-items-center" >
        <icon id="handle" class="has-text-grey is-size-6 grab-cursor p-1" icon="grip-vertical"/>
        <div>
          <input
              type="text"
              placeholder="Enter label"
              v-model="field.label"
              :disabled="locked"
              class="custom-field-input"
              @input="updateField('label', field.label)"/>
        </div>
      </div>
    </div>
    <div class="is-2-columns">
      <input
          type="text"
          v-model="field.placeholder"
          :disabled="locked"
          placeholder="Enter placeholder"
          class="custom-field-input"
          @input="updateField('placeholder', field.placeholder)"
      />
    </div>
    <div class="is-2-columns" :class="{'cursor-disabled has-tooltip-multiline': (field.linked_field || isParent)}" :data-tooltip="defaultFieldTooltip">
      <input type="text"
          class="custom-field-input has-tooltip"
          :disabled="field.linked_field || isParent || locked"
          :placeholder="field.linked_field ? '-' : 'Enter default'"
          v-model="field.default"
          @input="updateField('default', field.default)"/>
    </div>
    <div class="is-2-columns is-flex is-flex-column align-items-start">
      <div class="is-flex align-items-start">
        <div class="is-flex component-tag mt-0 mr-1 parent" v-if="optionsCount" @click="showingOptions = !showingOptions">
          <p class="is-size-7 has-text-weight-semibold p-025 pl-075" >
            {{optionsCount}}
          </p>
          <icon :icon="showingOptions ? 'chevron-up' : 'chevron-down'" class="is-size-7 has-text-primary" />
        </div>
        <action-button
            @click="showPredefinedValuesEditor"
            :disabled="locked"
            :data-tooltip="fieldHasErrors ? 'Missing data' : null"
            :left-icon="predefinedListIcon"
            :class="predefinedListColour"
            class="is-small is-rounded has-text-weight-semibold">
          <span v-if="optionsCount">Edit</span><span v-else>Add</span>
        </action-button>
      </div>
      <div class="mt-1" v-if="showingOptions && optionsCount">
        <div v-for="option in field.options" class="is-flex align-items-center pb-025" @click="showPredefinedValuesEditor">
          <p class="p-025 is-size-7" >
              {{ option.value }}
          </p>
          <div class="is-flex rounded-sml align-items-center " style="margin-left: 0.25rem; background-color: #e8e7e7"  >
            <intersect-icon :size="14" :color="'has-text-primary'" class="" v-if="field.linked_field"/>
            <p class="is-size-7 pr-025" v-if="field.linked_field && option.linked_value">
            {{ option.linked_value }}
          </p>
          </div>
        </div>
      </div>
    </div>
    <div class="is-2-columns" :class="{ 'is-flex align-items-center is-flex-grow-1': field.linked_field}" >
      <data-selector class="mb-0 "
                     :items="filteredCustomFields"
                     placeholder="test"
                     style="width: 80%"
                     searchable
                     :disabled="locked"
                     promptLabel="Select..."
                     value-key="field_id"
                     label-key="label"
                     v-model="field.linked_field"
                     @input="updateLinkedField">
        <action-button
            v-if="field.linked_field"
            class="is-cool-blue"
            slot="right"
            @click="clearLinkedField">
          <icon icon="times"/>
        </action-button>
      </data-selector>
      <icon icon="edit"
            @click="showPredefinedValuesEditor"
            v-if="field.linked_field"
            class="ml-1 has-text-primary-dark has-tooltip-right has-tooltip-multiline"
            :data-tooltip="linkedFieldTooltipText"/>
    </div>
    <div class="is-2-columns is-flex align-items-center justify-around">
      <switch-input
          classes="is-primary is-rounded is-small"
          class="mb-0"
          :value="field.is_required"
          :disabled="locked"
          @input="toggleRequiredField">
      </switch-input>
      <icon v-if="!locked" class="hover-highlight-danger has-text-grey is-size-5" icon="times-circle" @click="deleteField"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {predefinedValuesEditor} from "@/modals";
import TagIcon from "@/components/ui/icons/TagIcon.vue";
import MaximizeIcon from "@/components/ui/icons/MaximizeIcon.vue";
import DefaultGridIcon from "@/components/ui/icons/DefaultGridIcon.vue";

export default {
  components: {DefaultGridIcon, MaximizeIcon, TagIcon},
  props: {
    field: {
      type: Object,
      default: () => ({
        field_id: '',
        label: '',
        is_required: false,
        options: [],
        default: false,
        require_predefined_value: false
      }),
    },
    index: {
      type: Number,
    },
    locked: {
      type: Boolean,
    },
    customFields: {
      type: Array,
      default: () => []
    },
    badData: {
      type: Array,
      default: () => []
    }
  },

  created() {
    this.updateField('require_predefined_value', this.field.require_predefined_value ?? false)
  },

  data: () => ({
    showingOptions: false,
    linkedFieldTooltipText: 'You can dynamically filter which predefined values are available by linking this field to another custom field. You can only link this field to another field that also has at least one predefined value.'
  }),

  methods: {
    updateField(property, value) {
      this.$store.commit('assetType/updateField', {
        fieldId: this.field.field_id,
        property,
        value
      });
      this.$emit('changed')
    },
    updateLinkedField(){
      this.updateField('linked_field', this.field.linked_field)
      this.$store.commit('assetType/updateField', {
        fieldId: this.field.linked_field,
        property: 'require_predefined_value',
        value: true
      });
    },
    clearLinkedField(){
      this.updateField('linked_field', null)
    },
    toggleRequiredField() {
      this.$store.commit('assetType/toggleRequiredField', this.field.field_id);
    },
    async deleteField() {
      if (await this.$confirm({
        title: this.Convert('Delete Field'),
        message: this.Convert('Are you sure you want to remove this custom field? All assets with values assigned to this field will lose their saved value.')
      })) {
        this.$store.commit('assetType/deleteField', this.field)
      }
    },
    showPredefinedValuesEditor() {
      if(this.locked) return
      this.$store.commit('assetType/setCustomField', this.field)
      predefinedValuesEditor().then()
    },
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    filteredCustomFields(){
      return this.assetType.custom_fields?.filter(field => {
        // exclude this field
        return field.field_id !== this.field.field_id
        //exclude fields without predefined values
        && field.options?.length > 0
        //exclude fields already linked to this field
        && field.linked_field !== this.field.field_id
      })
    },
    predefinedValueCount() {
      const field =  this.assetType.custom_fields.find(customField => customField.field_id === this.field.field_id)
      return field.options?.length ?? 0
    },
    predefinedListColour() {
      if(this.fieldHasErrors) return 'is-danger has-tooltip'
      return this.predefinedValueCount > 0 ? 'is-primary' : 'has-text-grey'
    },
    predefinedListIcon() {
      if(this.fieldHasErrors) return 'exclamation-triangle'
      return this.optionsCount > 0 ? 'list' : 'plus'
    },
    optionsCount(){
      return this.field.options?.length
    },
    fieldHasErrors(){
      return this.badData.includes(this.field.field_id)
    },
    isParent(){
      return this.assetType.custom_fields?.filter(field => field.linked_field === this.field.field_id).length > 0
    },
    defaultFieldTooltip(){
      if(this.isParent) return 'This field is linked to another field. Default values can only be set from the Predefined Values List.'
      if(this.field.linked_field) return 'This field is linked to a parent field. Default values cannot be set for linked fields.'
      return null
    }
  },

  watch: {
    field: {
      handler() {
        this.$emit('changed')
      },
      deep: true,
    },
    index: {
      handler() {
        this.$emit('changed')
      },
    },
  },
}
</script>
