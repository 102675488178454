<template>
  <loader v-bind="{ loading }">
    <form class="box">
      <div class="columns">
        <div class="column">
          <text-input
              classes="is-medium"
              required
              placeholder="ex: Vehicle"
              :disabled="assetType.locked"
              :description="`A descriptive name for this ${Naming.AssetType.toLowerCase()}`"
              :error="$root.errors.name"
              :value="assetType.name"
              @input="name">
            Name
          </text-input>
        </div>
        <div class="column">
          <data-selector
              classes="is-medium"
              :description="`Indicate the level of risk that ${Naming.Assets.toLowerCase()} of this type will carry.`"
              :error="$root.errors.risk_id"
              :items="riskLevelList"
              :disabled="assetType.locked"
              value-key="id"
              label-key="level"
              :value="assetType.risk_id"
              @input="risk">
            Risk Level
          </data-selector>
        </div>
      </div>
      <div class="columns ">
        <div class="column">
          <h4 class="is-size-4 has-text-weight-light">{{ Naming.AssetType }} Image</h4>
          <p class="has-text-cool-blue-text mb-1">Select an image that would be displayed for all {{ Naming.Assets }} of this {{ Naming.AssetType }}</p>
          <action-button left-icon="camera" @click="uploadImage">Upload Image</action-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h4 class="is-size-4 has-text-weight-light">{{ Naming.Checklists }}</h4>

          <select-checklists v-if="!assetType.locked"
              :checklists="checklistList"
              :value="assetType.checklists"
              @input="setChecklists"/>

          <table v-else class="table is-fullwidth">
            <thead>
            <tr>
              <th>{{ Naming.Checklist }}</th>
              <th>{{ Naming.Checks }}</th>
              <th>Frequency</th>
              <th>Pass percentage</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="checklist in assetType.checklists" :key="checklist.id">
              <td>{{ checklist.name }}</td>
              <td>{{ checklist.checks.length }}</td>
              <td>
                <span v-if="checklist.frequency > 0">Every {{ checklist.frequency }} {{ 'day' | plural(checklist.frequency) }}</span>
                <span v-else>Does not repeat</span>
              </td>
              <td>{{ checklist.pass_percentage }}% to pass</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <notification class="is-cool-blue">
            <checkbox-input
                :value="assetType.enforce_checklist_order"
                :disabled="assetType.locked"
                @input="enforceChecklistOrder"
                classes="is-circle is-primary"
                :label="false"
                :inner-label="`Enforce ${Naming.Checklists.toLowerCase()} to be used in the order shown above. This will prevent ${Naming.Technicians.toLowerCase()} from selecting a ${Naming.Checklist.toLowerCase()} when scanning an ${Naming.Asset.toLowerCase()} of this type`"/>
          </notification>
        </div>
      </div>

      <div class="columns" v-if="barcodelessInspectionsAreEnabled">
        <div class="column">
          <notification class="is-cool-blue">
            <checkbox-input
                :value="assetType.allow_barcodeless_inspections"
                :disabled="assetType.locked"
                @input="allowBarcodelessInspections"
                classes="is-circle is-primary"
                :label="false"
                :inner-label="`Allow ${Naming.Inspections} to be actioned without scanning a barcode.`"/>
          </notification>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <h4 class="is-size-4 has-text-weight-light">{{ Naming.AssetGroups }}</h4>
          <data-selector
              multiple
              :items="assetGroupList"
              value-key="id"
              label-key="description"
              @input="updateAssetGroups"
              :value="assetType.zone_types"/>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <hr>
          <div class="buttons is-spaced mt-1">
            <submit-button :working="working" @submit="save" class="is-success is-rounded is-medium" left-icon="check">
              Save
            </submit-button>
            <action-button :disabled="!canDelete" :working="deleting" @click="deleteType"
                           class="is-danger is-medium is-rounded" left-icon="trash">Delete
            </action-button>
          </div>
        </div>
      </div>

    </form>
  </loader>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import SelectChecklists from './partials/SelectChecklists'
import {uploadAssetTypeImage} from "@/modals";

export default {

  components: {
    SelectChecklists,
  },

  data: () => ({
    loading: true,
    deleting: false,
    working: false,
    addingNewField: false
  }),

  created() {
    Promise.all([
      this.$store.dispatch('assetGroup/loadAssetGroupList'),
      this.$store.dispatch('checklist/loadChecklistList'),
      this.$store.dispatch('riskLevel/loadRiskLevelList'),
    ]).then(() => this.loading = false)
  },

  methods: {
    ...mapMutations('assetType', [
      'name',
      'setChecklists',
      'code',
      'clearType',
      'risk',
      'enforceChecklistOrder',
      'allowBarcodelessInspections',
      'updateAssetGroups'
    ]),
    uploadImage(){
      uploadAssetTypeImage()
    },

    save() {

      this.working = true
      this.$store.dispatch('assetType/save').then(() => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.assetType.saved))
      }).catch(() => {
        this.working = false
        this.$whoops()
      })
    },

    async deleteType() {
      const { title, message } = this.$lang.assetType.confirmDelete;
      const convertedTitle = await this.Convert(title);
      const convertedMessage = await this.Convert(message);

      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: this.$lang.assetType.confirmDelete.confirmText,
        confirmClass: 'is-danger'
      })) {
        this.deleting = true;
        this.$store.dispatch('assetType/delete').then(() => {
          this.$toast.success(this.Convert(this.$lang.assetType.deleted));
          this.$router.push({
            name: 'asset-type-index'
          });
        }).catch(error => {
          console.error(error);
          this.deleting = false;
          this.$whoops();
        });
      }
    }
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
    ...mapGetters('riskLevel', [
      'riskLevelList'
    ]),
    ...mapGetters('assetGroup', [
      'assetGroupList'
    ]),
    ...mapGetters('company', [
      'company'
    ]),
    ...mapGetters('auth', [
      'authenticated'
    ]),
    barcodelessInspectionsAreEnabled() {
      return this.isCompanyPrimaryUser && this.company.allow_barcodeless_inspections === true
    },
    canDelete(){
      return this.assetType.assets_count < 1 && !this.assetType.locked
    }
  }
}
</script>
