<template>
  <div>
    <div class="is-flex mb-1">
      <asset-filters
          class="flex-grow box is-marginless"
          :useAssetType="false"
          @filter="filterAssets"
          @clear="clearFilters"/>
      <div class="box ml-1">
        <dropdown-wrapper position="bottom-end">
          <action-button
            slot="trigger"
            :working="exporting"
            class="is-primary is-rounded">
            Export
          </action-button>
          <dropdown-item @click="exportToFile('xlsx')">Assets Export (XLSX)</dropdown-item>
          <dropdown-item @click="exportToFile('csv')">Assets Export (CSV)</dropdown-item>
          <dropdown-item @click="getAssetComponentsExport()">Asset Components Export (XLSX)</dropdown-item>
        </dropdown-wrapper>

        <div class="mt-1" v-if="assetType.id === 2213">
          <action-button
            slot="trigger"
            :working="exporting"
            @click="exportJpaReport"
            class="is-primary is-rounded">
            Export JPA Report
          </action-button>
        </div>

        <div class="mt-1" v-if="assetType.id === 4898">
          <action-button
            slot="trigger"
            :working="exporting"
            @click="exportEnerlyticsReport"
            class="is-primary is-rounded">
            Export Enerlytics Report
          </action-button>
        </div>

      </div>
    </div>
    <loader v-bind="{ loading }">
      <div class="box">
        <pager :pageable="assets" @nav="goToPage"/>
        <table class="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>{{ Naming.Asset }}</th>
              <th>Onboarded</th>
              <th>Last {{ Naming.Inspection }}</th>
              <th>Next {{ Naming.Inspection }}</th>
              <th>{{ Naming.Site }} / {{ Naming.Zone }}</th>
              <th>{{ Naming.Client }}</th>
              <th>Risk</th>
              <th>Dynamic Statuses</th>
              <th>Current Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="asset in assets.data" :key="asset.id">
              <td>
                <link-asset-manager class="has-text-weight-bold no-wrap" :asset="asset" :site-id="asset.zone.site_id"/>
              </td>
              <td>{{ asset.created_at | date('L') }}</td>
              <td>
                <latest-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></latest-inspection>
              </td>
              <td>
                <next-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></next-inspection>
              </td>
              <td>
                <link-site-manager v-if="asset.zone" :site="asset.zone.site"/><br>
                <span v-if="asset.zone">{{ asset.zone.name }}</span>
              </td>
              <td>
                <link-client-manager v-if="asset.zone" :client="asset.zone.site.client"/>
              </td>
              <td>{{ asset.risk.level }}</td>
              <td>
                <active-dynamic-statuses :active-statuses="asset.active_dynamic_statuses"/>
              </td>
              <td>
                <current-status :status="asset.current_status"/><br>
                <component-icon :asset="asset"/>                
              </td>
            </tr>
          </tbody>
        </table>
        <pager :pageable="assets" @nav="goToPage"/>
      </div>
    </loader>
  </div>
</template>

<script>
import { common as backend } from '@/api'
import { mapGetters } from 'vuex'
import AssetFilters from '@/components/search/admin/AssetFilters'
import download from 'downloadjs'
import startCase from 'lodash/startCase'
import { stringify } from 'qs'
import { omitBy } from 'lodash'
import ActiveDynamicStatuses from "@/views/asset/partials/ActiveDynamicStatuses.vue";

export default {

  components: {
    ActiveDynamicStatuses,
    AssetFilters
  },

  data: () => ({
    exporting: false,
    loading: true,
    filters: {}
  }),

  created() {
    this.loadAssets()
  },

  methods: {
    loadAssets() {
      this.loading = true
      backend.loadPath({
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      }, ({ data }) => {
        this.$store.commit('asset/setAssets', data)
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    goToPage(path) {
      this.$router.push(path)
    },

    filterAssets(filters) {
      this.$router.push({
        name: 'asset-type-assets',
        query: filters
      })
      this.filters = filters
    },

    clearFilters() {
      this.filterAssets({})
    },

    exportToFile(type) {
      this.exporting = true
      backend.downloadFile(
        `/api/asset-type/${this.assetType.id}/assets/export/${type}?` + stringify(omitBy(this.$route.query, value => {
          return value === '' || value === null
        })),
        (response) => {
          download(
            response.data,
            `${startCase(this.assetType.name)} - Assets.${type}`,
            type === 'xlsx' ? 'application/vnd.ms-excel' : 'text/csv'
          )
          this.exporting = false
        },
        (error) => {
          this.exporting = false
        }
      )
    },

    getAssetComponentsExport() {
      this.exporting = true
      backend.downloadFile(
          `/api/asset-type/${this.assetType.id}/asset-components-export?` + stringify(omitBy(this.$route.query, value => {
            return value === '' || value === null
          })),
          (response) => {
            download(
                response.data,
                `${startCase(this.assetType.name)} - Asset Components.xlsx`,
                'application/vnd.ms-excel'
            )
            this.exporting = false
          },
          (error) => {
            this.exporting = false
          }
      )
    },
    exportJpaReport() {
      this.exporting = true
      backend.downloadFile(
        `/api/asset-type/${this.assetType.id}/assets/export/ontec?` + stringify(omitBy(this.$route.query, value => {
          return value === '' || value === null
        })),
        (response) => {
          download(
            response.data,
            `${startCase(this.assetType.name)} - ${this.Convert(Assets)}.xlsx`,
            'application/vnd.ms-excel'
          )
          this.exporting = false
        },
        (error) => {
          this.exporting = false
        }
      )
    },

    exportEnerlyticsReport(){
      this.exporting = true
      backend.downloadFile(
        `/api/asset-type/${this.assetType.id}/assets/export/enerlytics?` + stringify(omitBy(this.$route.query, value => {
          return value === '' || value === null
        })),
        (response) => {
          download(
            response.data,
            `${startCase(this.assetType.name)} - ${this.Convert(Assets)}.xlsx`,
            'application/vnd.ms-excel'
          )
          this.exporting = false
        },
        (error) => {
          this.exporting = false
        }
      )
    }
  },

  computed: {
    ...mapGetters('asset', [
      'assets'
    ]),
    ...mapGetters('assetType', [
      'assetType'
    ])
  },

  watch: {
    '$route': 'loadAssets'
  }

}
</script>
