<template>
  <div v-if="!canSave" class="notification rounded-sml has-background-white has-border-thin-grey">
    <div class="is-flex">
      <icon icon="exclamation-circle" class="has-text-danger"/>
      <p class="has-text-weight-normal pl-025">We found some issues with your inputs. Please review and resolve the following:</p>
    </div>
    <ul class="ml-1 mt-1">
      <li v-if="hasDuplicateLabel">- Two of your {{ Naming.CustomFields }} have the same label. The field label should be unique for this {{ Naming.AssetType }}.</li>
      <li v-if="hasAnEmptyLabel">- One of your {{ Naming.CustomFields }} has an empty label.</li>
      <li v-if="hasDuplicateFieldId">- Two of your {{ Naming.CustomFields }} have the same ID. Please remove the new field and re-add it.</li>
      <li v-if="missingData && missingData.length">
        - Linked fields need at least one predefined value:
        <strong>{{ dataListToString(missingData) }}</strong>
      </li>
      <li v-if="mismatchedData && mismatchedData.length">
        - Linked fields have unassigned predefined values:
        <strong>{{ dataListToString(mismatchedData) }}</strong>
      </li>
      <li v-if="unassignedOptions && unassignedOptions.length">
        - {{ Naming.CustomFields }} with linked fields must have every parent predefined value assigned to a child predefined value. Missing values in:
        <strong>{{ dataListToString(unassignedOptions) }}</strong>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    canSave: Boolean,
    hasDuplicateLabel: Boolean,
    hasAnEmptyLabel: Boolean,
    hasDuplicateFieldId: Boolean,
    missingData: Array,
    mismatchedData: Array,
    unassignedOptions: Array
  },
  methods: {
    dataListToString(dataList) {
      return dataList.map(data => data.label).join(', ')
    }
  }
}
</script>