<template>
  <loader v-bind="{ loading }" >
    <div class="is-flex justify-between">
      <div class="is-flex align-items-center">
        <braces-icon :size="32" :color="'has-text-primary'" style="margin-bottom: 0.3rem; margin-right: 0.5rem" />
        <h3 class="is-size-4">Custom Fields</h3>
        <icon
            icon="info-circle"
            class="ml-1 mb-1 is-small has-text-grey-lighter has-tooltip-right has-tooltip-multiline"
            :data-tooltip="customFieldsTooltip"/>
      </div>
      <div>
        <action-button
            v-if="!addingCustomField"
            :working="working"
            :disabled="assetType.locked"
            left-icon="plus-circle"
            class="is-primary is-inverted rounded-sml has-text-weight-semibold mr-1"
            @click="addingCustomField = true">
          Add New
        </action-button>
        <action-button
            :working="working"
            :disabled="!canSave || assetType.locked"
            left-icon="save"
            class="is-success rounded-sml has-text-weight-semibold"
            @click="store">
          Save
        </action-button>
      </div>
    </div>
    <div v-if="!hasFields && !addingCustomField" class="p-2 has-background-white rounded-sml mt-1">
      You have not created any {{Naming.CustomFields.toLowerCase()}} for this {{Naming.AssetType.toLowerCase()}} yet. Click on <strong>Add New</strong> to add.
    </div>
    <div class="mt-2 pb-6">
        <error-messages
          class="mt-1"
          :can-save="canSave"
          :has-an-empty-label="hasAnEmptyLabel"
          :has-duplicate-label="hasDuplicateLabel"
          :has-duplicate-field-id="hasDuplicateFieldId"
          :missing-data="missingData"
          :mismatched-data="mismatchedData"
          :unassigned-options="unassignedOptions"
        />
      <new-custom-field
          class="mt-2 mb-2"
          v-if="!assetType.locked && addingCustomField"
          @close="addingCustomField = false"
          @changed="saved = false"/>
      <div v-if="hasFields">
        <custom-fields-table-header/>
        <draggable
          handle="#handle"
          :animation="200"
          v-model="assetType.custom_fields"
          group="custom_fields">
          <transition-group>
            <custom-field
              v-for="(field, index) in assetType.custom_fields"
              :field="field"
              :index="index"
              :custom-fields="assetType.custom_fields"
              :bad-data="fieldsWithErrors"
              @changed="saved = false"
              :locked="assetType.locked"
              :key="field.field_id"/>
          </transition-group>
        </draggable>
      </div>
    </div>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomField from './partials/CustomField'
import NewCustomField from './partials/NewCustomField'
import customFieldValidator from "@/utils/customFieldValidator";
import arrayHelper from '@/utils/arrayHelper.js'
import CustomFieldsTableHeader from "@/views/asset-type/partials/CustomFieldsTableHeader.vue";
import ErrorMessages from "@/views/asset-type/partials/ErrorMessages.vue";

export default {

  components: {
    ErrorMessages,
    CustomFieldsTableHeader,
    CustomField,
    NewCustomField
  },

  data: () => ({
    loading: true,
    working: false,
    saved: true,
    addingCustomField: false,
    missingData: null,
    mismatchedData: null,
    unassignedOptions: null
  }),

  created() {
    Promise.all([
      this.$store.dispatch('assetType/loadAssetType', this.$route.params.assetType)
    ]).then(() => this.loading = false)
  },

  async beforeRouteLeave(to, from, next) {
    if (this.saved === false) {
      if (await this.confirmLeave()) {
        next()
      }
    } else {
      next()
    }
  },

  computed: {
    ...mapGetters('assetType', [
      'assetType'
    ]),
    hasFields(){
      return this.assetType.custom_fields?.length > 0
    },
    customFieldsTooltip(){
      return `${this.Naming.CustomFields} are considered fixed data points. These values will typically not change over time and are best suited for things like serial numbers etc. For data points that fluctuate or change, consider creating a ${this.Naming.Check.toLowerCase()} on a ${this.Naming.Checklist.toLowerCase()} instead.`
    },
    canSave() {
      return !(this.hasAnEmptyLabel || this.hasDuplicateLabel || this.hasDuplicateFieldId || this.missingData?.length || this.mismatchedData?.length || this.unassignedOptions?.length)
    },
    hasAnEmptyLabel() {
      return this.assetType.custom_fields?.some(customField => !customField.label || customField.label.trim().length < 1)
    },
    hasDuplicateLabel() {
      return arrayHelper.findDuplicates(this.assetType.custom_fields, 'label').length > 0
    },
    hasDuplicateFieldId() {
      return arrayHelper.findDuplicates(this.assetType.custom_fields, 'field_id').length > 0
    },
    fieldsWithErrors(){
      const missingIds = this.missingData?.map(field => field.field_id)
      const mismatchedIds = this.mismatchedData?.map(field => field.field_id)
      const unassignedIds = this.unassignedOptions?.map(field => field.field_id)
      return missingIds?.concat(mismatchedIds)?.concat(unassignedIds)
    },
    parentFields(){
      const parentIds = this.assetType.custom_fields.map(field => field.linked_field)
      return this.assetType.custom_fields.filter(field => {
        return parentIds.includes(field.field_id)
      })
    }
  },

  methods: {
    store() {
      this.working = true
      this.$store.dispatch('assetType/save').then(() => {
        this.$store.dispatch('assetType/loadAssetType', this.$route.params.assetType)
        this.working = false
        this.saved = true
        this.$toast.success(this.Convert(this.$lang.assetType.saved))
      }).catch(() => {
        this.working = false
        this.saved = false
        this.$whoops()
      })
    },
    async confirmLeave() {
      return this.$confirm({
        title: "Warning",
        message: `Leave without saving?`,
        confirmText: "Leave Page",
        cancelText: "Continue Editing",
        confirmClass: 'is-danger',
        cancelClass: 'is-info',
      })
          .catch(() => this.$whoops())
    },
    hasMissingLinkedValues(){
      this.missingData = customFieldValidator.findMissingValues(this.assetType.custom_fields, this.parentFields);
    },
    findChildrenWithoutAllOptionsAssigned(){
      this.unassignedOptions = customFieldValidator.findChildrenWithoutAllOptionsAssigned(this.assetType.custom_fields);
    },
    findInvalidLinkedValues() {
      this.mismatchedData = customFieldValidator.findInvalidLinkedValues(this.assetType.custom_fields);
    },
  },

  watch: {
    "assetType": {
      handler() {
        this.hasMissingLinkedValues()
        this.findInvalidLinkedValues()
        this.findChildrenWithoutAllOptionsAssigned()
        this.saved = false
      },
      deep: true,
    },
  },
}
</script>