<template>
  <div style="margin-bottom: 2px;" class="grid has-12-columns gap-1 pt-075 pb-075 rounded-sml is-uppercase tracking-wide has-text-grey has-background-white" :class="{'has-background-white-bis' : creatingAssetType}">
    <div class="is-2-columns is-flex justify-start align-items-center pl-1 "><tag-icon style="margin-right: 0.5rem; margin-left: 0.5rem" :color="'has-text-primary'"/>Label</div>
    <div class="is-2-columns is-flex justify-start align-items-center"><maximize-icon style="margin-right: 0.5rem" :color="'has-text-primary'"/>Placeholder</div>
    <div class="is-2-columns is-flex justify-start align-items-center"><default-grid-icon style="margin-right: 0.5rem" :color="'has-text-primary'" :size="18"/>Default</div>
    <div class="is-2-columns is-flex justify-start align-items-center"><icon style="margin-right: 0.5rem" icon="list" class="has-text-primary" /> Predefined Values</div>
    <div class="is-2-columns is-flex justify-start align-items-center"><intersect-icon style="margin-right: 0.25rem" class="" :color="'has-text-primary'"/>Linked Field <new-feature-icon style="margin-left: 0.5rem" :tooltip-text="'Filters the available predefined values based on the value chosen for the linked custom field'"/></div>
    <div class="is-2-columns is-flex justify-start align-items-center pl-1"><icon icon="asterisk" class="has-text-primary is-size-7" />Required</div>
  </div>
</template>
<script>
  import DefaultGridIcon from "@/components/ui/icons/DefaultGridIcon.vue";
  import MaximizeIcon from "@/components/ui/icons/MaximizeIcon.vue";
  import TagIcon from "@/components/ui/icons/TagIcon.vue";

  export default {
    components: {TagIcon, MaximizeIcon, DefaultGridIcon},
    props: {
      creatingAssetType: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>